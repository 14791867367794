import React from 'react';

export const AgendaRu = () => (
  <div style={{ textAlign: 'justify' }}>
    <p style={{ textAlign: 'center' }}>
      <b>
        ПУБЛИЧНИЙ ДОГОВОР ОБ УСЛУГАХ ТЕРРИТОРИАЛЬНО-РАСПРЕДЕЛЕННОГО
        ВИДЕОНАБЛЮДЕНИЯ <br />
        (на условиях публичной оферты)
      </b>
    </p>
    <p>
      Сервис территориально-распределенного видеонаблюдения ipteamcloud, далее в
      тексте &laquo;Исполнитель&raquo;, данным документом предлагает любым
      юридическим лицам и/или дееспособным физическим лицам (далее в тексте
      &mdash; &laquo;Пользователь&raquo;) составить Договор о предоставлении
      услуги ТЕРРИТОРИАЛЬНО-РАСПРЕДЕЛЕННОГО ВИДЕОНАБЛЮДЕНИЯ (далее в тексте
      &mdash; Услуга) на следующих условиях:
    </p>
    <p>
      <b>1. Термины и определения</b>
    </p>
    <p>
      <b>Публичная оферта</b> &mdash; предложение Исполнителя (изложенное на
      Сайте Исполнителя), адресованное неопределенному кругу физических лиц,
      заключить договор на определенных условиях.
    </p>
    <p>
      <b>Договор</b> &mdash; договор о предоставлении услуги
      территориально-распределенного видеонаблюдения, заключенный между
      Исполнителем и Пользователем на условиях Публичной оферты в момент Акцепта
      Пользователем его условий.
    </p>
    <p>
      <b>Акцепт</b> &mdash; полное и безусловное, безоговорочное принятие
      Пользователем условий Публичной оферты и Правил. Порядок Акцепта определен
      статьей 4 настоящего Договора.
    </p>
    <p>
      <b>Пользователь</b> &mdash; лицо, осуществившее акцепт (принятие) условий
      настоящего Договора и пользуется услугой.
    </p>
    <p>
      <b>Конечное оборудование</b> &mdash; установленное у Пользователя
      оборудования (маршрутизатор, IP видеокамера, видеорегистратор, компьютер и
      т.д.), необходимое для получения Услуги.
    </p>
    <p>
      <b>Логин</b> &mdash; уникальный алфавитно-цифровой набор символов,
      идентифицирующая пользователя Услуги и вместе с паролем используется для
      предоставления Пользователю разрешения доступа в личный кабинет. Логин
      должен быть уникальным в пределах данной системы.
    </p>
    <p>
      <b>E-mail</b> &mdash; уникальный алфавитно-цифровой набор символов,
      идентифицирующий адрес электронной почты пользователя, которая
      используется Исполнителем для информирования пользователя о некоторых
      действий в Личном Кабинете, а именно: авторизации пользователя, изменений
      настроек, оплаты услуг и прочее.
    </p>
    <p>
      <b>IP адрес</b> &mdash; интернет-протокол-адрес Пользователя, который
      используется для передачи данных пользователя через телекоммуникационные
      сети Провайдера.
    </p>
    <p>
      <b>Провайдер</b> &mdash; сторонняя организация, предоставляющая на
      коммерческой или любой другой основе Пользователю доступ к сети Интернет.
    </p>
    <p>
      <b>Cайт Исполнителя &mdash; </b>
      веб-страница Исполнителя в Интернет, которая находится по адресу
      <a
        href="http://www.ipteamcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.ipteamcloud.com
      </a>
      , и является основным источником информирования Пользователей и доступа в
      Личный Кабинет.
    </p>
    <p>
      <b>Личный Кабинет</b> &mdash; индивидуальный раздел на сайте Исполнителя
      для каждого пользователя, используя который, пользователь может
      самостоятельно изменять тарифные планы и параметры Услуги, получать
      информацию по объемам оказанных услуг и самостоятельно управлять услугой
      (условия пользования личным кабинетом указаны на сайте Исполнителя).
    </p>
    <p>
      <b>Тарифный план или Тариф </b>
      &mdash; совокупность предложений, предложенных Пользователю Исполнителем,
      устанавливающие объем и стоимость услуг, способы расчетов, особенности
      тарификации, и другая информация, которая утверждается Исполнителем
      самостоятельно и публикуется на Сайте Исполнителя.
    </p>
    <p>
      <b>Дополнительные услуги</b> &mdash; набор дополнительных, не включенных в
      тарифный план, опционных услуг, которые Пользователь может заказать по
      своему желанию дополнительно.
    </p>
    <p>
      <b>INTERNET або Интернет</b> &mdash; всемирная информационная система
      общего доступа, которая логически связана глобальным адресным
      пространством и базируется на Интернет-протоколе, определенном
      международными стандартами.
    </p>
    <p>
      <b>Расчетный период</b> &mdash; период, равный 30 (тридцати) календарным
      дням, в течение которого Стороны осуществляют выполнение настоящего
      Договора.
    </p>
    <p>
      <b>Контент</b> &mdash; потоковое видео в режиме реального времени или
      архива, создаваемого Пользователем (оборудованием Пользователя) и хранится
      на серверах Исполнителя.
    </p>
    <p>
      <b>Личный счет</b> &mdash; счет в биллинговой системе Исполнителя, на
      котором фиксируются авансовые платежи Пользователя и суммы средств,
      удержанные (списанные) из данных платежей в качестве оплаты за
      предоставленные услуги.
    </p>
    <p>
      Все другие термины употребляются в значении определенном согласно
      действующему законодательству Украины.
    </p>
    <p>
      <b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b>
    </p>
    <p>
      2.1. В соответствии со ст. 633 Гражданского кодекса Украины настоящий
      Договор является публичным договором, заключенным на условиях публичной
      оферты.
    </p>
    <p>
      2.2. Все условия настоящего Договора является обязательным для Исполнителя
      и Пользователя. Перед началом получения услуг каждый Пользователь обязан
      ознакомиться с условиями настоящего Договора. Если Пользователь не
      согласен с какими-либо условиями Договора, он не вправе пользоваться
      услугами Исполнителя.
    </p>
    <p>
      2.3. Пользователем, который акцептует публичную оферту на заключение
      настоящего Договора, может быть физическое лицо, имеющее полную
      гражданскую дееспособность, или юридическое лицо.
    </p>
    <p>
      2.4. Стороны согласились, что сайт Исполнителя{' '}
      <a
        href="http://www.ipteamcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.ipteamcloud.com
      </a>
      является доступным и максимально приемлемым для Пользователя источником
      информации об услугах и правил их предоставления, действующих тарифов
      Исполнителя.
    </p>
    <p>
      2.5. Услуга видеонаблюдения через Интернет, предоставляемая Исполнителем
      не является охранной системой.
    </p>
    <p>
      <b>3. ПРЕДМЕТ ДОГОВОРА</b>
    </p>
    <p>
      3.1. Предметом настоящего Договора является регулирование отношений между
      Исполнителем и Пользователем, при использовании последним Услуги
      территориально-распределенного видеонаблюдения через Интернет (далее по
      тексту &mdash; Услуга).
    </p>
    <p>
      3.2. Исполнитель обязуется предоставить Пользователю Услугу, в
      соответствии с выбранным тарифным планом и (при наличии) дополнительных
      опций, информация о котором размещена на сайте Исполнителя, а Пользователь
      обязуется оплачивать Услугу в соответствии с условиями настоящего
      Договора, в размере стоимости выбранного тарифного плана и избранных
      пользователем дополнительных опций, определенных в тарифных опциях.
    </p>
    <p>
      <b>4. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</b>
    </p>
    <p>
      4.1. Настоящий Договор считается заключенным без подписания с даты акцепта
      (принятия) условий данного Договора, определенной в соответствии с п. 4.2
      - п. 4.3 настоящего Договора.
    </p>
    <p>
      4.2. Для физических лиц, свидетельством полного и безоговорочного акцепта
      (принятия) условий данного Договора является осуществление физическим
      лицом процедуры регистрации на Сайте Исполнителя, в течение которой
      физическое лицо принимает условия данного Договора, и Договор считается
      заключенным без подписания в каждом отдельном случае. Датой заключения
      настоящего Договора в этом случае является дата регистрации /
      перерегистрации на Сайте Исполнителя.
    </p>
    <p>
      4.3. Для юридических лиц принятия условий настоящего Договора считается
      осуществимым с момента первой оплаты юридического лица по выставленному
      Исполнителем счету-фактуре. Или путем подписания письменного договора (по
      желанию Пользователя-юридического лица).
    </p>
    <p>
      <b>5. УСЛОВИЯ, ПОРЯДОК ПРЕДОСТАВЛЕНИЯ И ИСПОЛЬЗОВАНИЯ УСЛУГИ</b>
    </p>
    <p>5.1. Необходимые условия для предоставления Услуги:</p>
    <p>
      5.1.1. Наличие доступа к сети Интернет со статическим (неизменным)
      IP-адресом;
    </p>
    <p>
      5.1.2. Исходящая скорость соединения с сетью Интернет, на месте установки
      IP видеокамер, не менее 2Мб/сек на каждый видеопоток (на 1 IP-видеокамеру,
      или 1 канал видеосервера, или 1 канал видеорегистратора, в направлении от
      пользователя - UPLOAD).
    </p>
    <p>5.2. Порядок предоставления Услуги:</p>
    <p>5.2.1. Пользователь регистрируется на сайте Исполнителя.</p>
    <p>
      5.2.2. В дальнейшем Пользователь с помощью Личного Кабинета может добавить
      свое оборудование, выбрать* Тарифный план, дополнительные опции по
      желанию;
    </p>
    <p>
      5.2.3. Пользователь оплачивает выбранный объем услуг и начинает
      пользоваться услугой;
    </p>
    <p>
      5.2.4. В рамках Услуги Пользователю из Личного Кабинета доступен набор
      действий* по управлению, подключению/удалению Конечного Оборудования,
      управления контентом, предоставления/изъятия доступа к собственному
      Контенту другим пользователям, и другое.
    </p>
    <p>
      * Описание доступных Тарифов, сервисов, опций и действий по управлению
      услугой, что в совокупности составляют Услугу, и порядок пользования ими
      отражено в документе &laquo;Инструкция пользователя&raquo;, который
      находится в Личном Кабинете в разделе &laquo;Помощь&raquo;.
    </p>
    <p>
      <b>6. ПРАВА И ОБЯЗАННОСТИ СТОРОН</b>
    </p>
    <p>6.1. Исполнитель обязуется:</p>
    <p>
      6.1.1. Предоставлять Пользователю Услугу в соответствии с условиями
      настоящего Договора и в соответствии с действующим законодательством
      Украины.
    </p>
    <p>
      6.1.2. Предупреждать пользователя о сокращении перечня или прекращении
      предоставления услуг в случаях и порядке, предусмотренных настоящим
      Договором и законодательством Украины, в том числе в случае наличия
      задолженности в пользователя.
    </p>
    <p>
      6.1.3. Вести учет объема и стоимости предоставленных Пользователю Услуг.
    </p>
    <p>
      6.1.4. Информировать пользователя о новых видах услуг, которые Исполнитель
      может оказывать Пользователю.
    </p>
    <p>
      6.1.5. Информировать пользователя об изменении перечня и / или стоимости
      услуг путем размещения сообщений на Сайте Исполнителя.
    </p>
    <p>6.2. Пользователь обязуется:</p>
    <p>
      6.2.1. Ознакомиться с правилами и условиями предоставления Услуги, и
      выполнять условия, предусмотренные настоящим Договором.
    </p>
    <p>
      6.2.2. Использовать Услугу исключительно для целей и способом,
      определенным Исполнителем в пределах и в соответствии с нормами
      действующего Законодательства.
    </p>
    <p>
      6.2.3. Не использовать Услугу для распространения любого другого Контента,
      в том числе того, что нарушает нормы действующего законодательства
      Украины.
    </p>
    <p>
      6.2.4. Не размещать и не транслировать Контент, содержащий информацию
      порнографического и эротического характера, или другую информацию
      относительно размещения (опубликования) которой установлены ограничения.
    </p>
    <p>
      6.2.5. При использовании Услуги в местах общего пользования, сообщать
      третьим лицам о видеонаблюдения (в том числе путем размещения
      соответствующих письменных сообщений).
    </p>
    <p>
      6.2.6. Не совершать и не пытаться получить доступ к Услуге другим
      способом, кроме как через интерфейс(-ы), предоставленные Исполнителем.
    </p>
    <p>
      6.2.7. Не совершать любые действия, которые препятствуют предоставлению
      Услуги или нарушают ее функционирование, или иным образом нарушают
      интересы Исполнителя.
    </p>
    <p>
      6.2.8. Контролировать наличие на своем лицевом счете достаточной
      минимальной суммы средств для возможности получения Услуги и проводить
      сверку с целью уточнения сумм, внесенных авансовым платежом.
    </p>
    <p>
      6.2.9. Своевременно принимать и в полном объеме осуществлять оплату
      предоставленной Исполнителем Услуги в сроки и порядке, предусмотренные
      настоящим Договором.
    </p>
    <p>
      6.2.10. Немедленно (но в любом случае не позднее чем через 24 часа)
      сообщить Исполнителю, в случае получения сведений о любом
      несанкционированном использовании пароля и Личного Кабинета.
    </p>
    <p>
      6.2.11. Не использовать Услугу в целях, запрещенных действующим
      законодательством Украины, в том числе не допускать сбор, хранение,
      использование и распространение конфиденциальной информации о лице (как
      юридическом, так и физическом) или изображение физического лица без его
      согласия, а также не допускать сбора и распространения информации
      юридического лица, содержащей / возможно содержащей коммерческую тайну.
    </p>
    <p>
      6.2.12. Пользоваться услугой только с целью видеонаблюдения за
      помещениями, которые Пользователь эксплуатирует на законных основаниях.
    </p>
    <p>
      6.2.13. Надлежащим образом исполнять иные обязанности, предусмотренные
      настоящим Договором и действующим законодательством Украины.
    </p>
    <p>6.3. Исполнитель имеет право:</p>
    <p>
      6.3.1. Своевременно получать оплату за предоставленную Пользователю Услугу
      согласно условиям настоящего Договора.
    </p>
    <p>
      6.3.2. Приостановить исполнение настоящего Договора в случае недостатка
      денежных средств на лицевом счете пользователя. При таком приостановлении
      исполнения настоящего Договора, Исполнитель не несет ответственности за
      неисполнение или ненадлежащее исполнение обязательств по Договору.
      Возобновление действия настоящего Договора происходит исключительно после
      получения Исполнителем от Пользователя оплаты за предоставленные услуги в
      полном объеме и авансовой оплаты за предоставление Услуги в следующем
      расчетном периоде.
    </p>
    <p>
      6.3.3. В порядке, предусмотренном настоящим Договором, сокращать или
      прекращать предоставление Услуги Пользователю в случае нарушения им
      условий настоящего Договора.
    </p>
    <p>
      6.3.4. Сокращать объем, временно или полностью прекращать предоставление
      услуг или расторгнуть Договор в одностороннем порядке в случае поступления
      жалоб или иных сообщений о:
    </p>
    <ul>
      <li>
        <p>
          размещении у Пользователя информации, оскорбляющей достоинство других
          людей;
        </p>
      </li>
      <li>
        <p>
          размещении у Пользователя информации эротического или
          порнографического характера (согласно статье 1 Закона Украины &laquo;О
          защите общественной морали&raquo;);
        </p>
      </li>
      <li>
        <p>
          размещении у Пользователя материалов, противоречащих действующему
          законодательству Украины.
        </p>
      </li>
    </ul>
    <p>
      В случае поступления такой жалобы или сообщения Исполнитель может удалить
      весь Контент пользователя, заблокировать все Конечное оборудование сообщив
      об этом Пользователю любым способом (в том числе, но не исключительно,
      электронным письмом, SMS сообщением и т.д.).
    </p>
    <p>6.4. Пользователь имеет право:</p>
    <p>
      6.4.1. Получать от Исполнителя информацию об Услуге и условиях ее
      предоставления.
    </p>
    <p>
      6.4.2. Изменять в соответствии со своими интересами перечень и параметры
      сервисов, которые составляют Услугу в соответствии с порядком и условиями
      настоящего Договора.
    </p>
    <p>
      6.4.3. Обращаться к Исполнителю с предложениями по улучшению качества
      Услуги.
    </p>
    <p>6.4.4. Изменять / восстанавливать пароль своего Личного Кабинета.</p>
    <p>6.4.5. Менять тарифные планы в порядке, определенном Исполнителем.</p>
    <p>
      6.4.6. Пользователь имеет также другие права, предусмотренные действующим
      законодательством Украины и настоящим Договором.
    </p>
    <p>
      <b>7. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</b>
    </p>
    <p>
      7.1. Для получения Услуги по настоящему Договору Пользователь оплачивает
      стоимость услуги в размере и в порядке, определенными выбранным тарифным
      планом.
    </p>
    <p>
      7.2. Стоимость услуг по настоящему Договору определяются тарифными планами
      и указаны на Сайте Исполнителя.
    </p>
    <p>
      7.3. Оплата услуг осуществляется путем внесения авансового платежа в
      безналичной форме с помощью банковского перевода или с использованием
      платежных средств, которые указаны на Сайте Исполнителя.
    </p>
    <p>
      7.4. Возврат остатка средств с лицевого счета Пользователя в случае
      расторжения договорных отношений не происходит.
    </p>
    <p>
      7.5. Изменение тарифного плана осуществляется с момента перехода
      Пользователем в Личном Кабинете на другой тарифный план и после обработки
      Исполнителем такого перехода.
    </p>
    <p>
      7.6. Исполнитель имеет право в одностороннем порядке изменять стоимость
      услуг согласно тарифных планов и / или тарифные планы, при этом все
      изменения публикуются на Сайте. Не расторжение Пользователем Договора в
      указанный срок и продолжение использования Услуги свидетельствуют о
      согласии Пользователя с изменениями стоимости услуг и / или тарифных
      планов.
    </p>
    <p>
      <b>8. Гарантийные обязательства и ответственность сторон</b>
    </p>
    <p>
      8.1. За невыполнение или ненадлежащее выполнение обязательств по
      настоящему Договору Исполнитель и Пользователь несут ответственность в
      соответствии с действующим законодательством Украины и условиями
      настоящего Договора.
    </p>
    <p>
      8.2. Исполнитель гарантирует сохранение записей видеопотоков с Конечного
      Оборудования Пользователя, в соответствии с выбранным тарифным планом, в
      режиме записи по детекции движения в кадре. То есть, если изображение с
      камеры статическое (в кадре ничего не происходит), запись не ведется.
    </p>
    <p>
      8.3. Исполнитель гарантирует работу сервиса в режиме 24/7, за исключением
      указанных перерывов в работе сервиса ipteamcloud:
    </p>
    <ul>
      <li>
        <p>
          не более 12 часов в месяц суммарно из-за профилактических причин и/или
          аварийных остановок в работе сервиса ipteamcloud на стороне
          Исполнителя;
        </p>
      </li>
      <li>
        <p>
          сбои в работе сервиса ipteamcloud по техническим причинам на стороне
          Пользователя не учитываются (отсутствие электроэнергии, или соединения
          с сетью Интернет на стороне Пользователя, либо умышленное
          выключение/повреждение Конечного Оборудования Пользователя и т.д.);
        </p>
      </li>
      <li>
        <p>
          если перерыв (вынужденный или профилактический) в работе сервиса
          ipteamcloud по вине Исполнителя суммарно составит более 12 часов в
          месяц, Исполнитель делает скидку в размере 50% Пользователю на
          следующий месяц пользования сервисом ipteamcloud;
        </p>
      </li>
      <li>
        <p>
          если перерыв (вынужденный или профилактический) в работе сервиса
          ipteamcloud по вине Исполнителя суммарно составит более 72 часов в
          месяц, Исполнитель делает скидку в размере 100% Пользователю на
          следующий месяц пользования сервиса ipteamcloud.
        </p>
      </li>
    </ul>
    <p>
      8.4. В любом случае, ответственность Исполнителя перед Пользователем
      ограничивается эквивалентом размера оплаты Пользователя за текущий
      расчетный период (денежным размером оплаченного Тарифного плана за текущий
      период).
    </p>
    <p>
      8.5. Исполнитель не несет ответственность за содержание и качество
      полученной Пользователем из сети Интернет информации, по ее дальнейшее
      использование и хранение пользователем.
    </p>
    <p>8.6. Исполнитель не несет ответственности перед Пользователем за:</p>
    <p>
      8.6.1. Непредоставление или ненадлежащее предоставление услуг при условии
      наступления каких-либо обстоятельств, которые возникли не по вине
      Исполнителя.
    </p>
    <p>
      8.6.2. Техническое состояние каналов, арендованных Исполнителем в
      Оператора.
    </p>
    <p>
      8.6.3. Ухудшение качества Услуги, перебои в предоставлении Услуги, любые
      повреждения, которые были нанесены Контента Пользователя в результате
      использования им сети Интернет, включая повреждения или потерю данных в
      результате задержек, неполучения, ошибочной адресации или прерывания
      Услуги, которое было вызвано ненадлежащим техническим состоянием
      арендованных каналов и перебоями в работе, которые возникли прямо или
      косвенно по причинам, находятся вне контроля и влияния Исполнителя.
    </p>
    <p>
      8.6.4. Качество функционирования отдельных сегментов и узлов сети
      Интернет, не принадлежащих Исполнителю.
    </p>
    <p>
      8.6.5. За качество и функционирования конечного Оборудование пользователя.
    </p>
    <p>
      8.7. Исполнитель не несет какой-либо материальной, финансовой или иной
      ответственности за возможные последствия использования Пользователем
      сервиса ipteamcloud и/или претензий, которые возникли или могут возникнуть
      со стороны Пользователя или со стороны третьих лиц к Пользователю и/или
      Исполнителю вследствие использования Пользователем сервиса ipteamcloud.
    </p>
    <p>
      8.8. Пользователь несет ответственность за использование Услуги в целях,
      запрещенных действующим законодательством Украины, в том числе за сбор,
      хранение, использование и распространение конфиденциальной информации о
      лице (как юридическом, так и физическом) или изображение физического лица
      без его согласия, а также сбор и распространение информации о юридическом
      лице, содержащее / возможно содержащее коммерческую тайну.
    </p>
    <p>
      8.9. Пользователь несет ответственность перед третьими лицами за
      разглашение информации, которая стала известна при пользовании Услугой.
    </p>
    <p>
      8.10. Пользователь несет ответственность перед Исполнителем за вред,
      причиненный деловой репутации последнего и обязуется возместить
      причиненный ущерб в полном объеме. Стороны согласовали, что вредом,
      причиненным деловой репутации считается:
    </p>
    <p>
      8.10.1. Удовлетворение судом исков третьих лиц к Исполнителю, основанием
      для которых стало ненадлежащее исполнение Пользователем своих обязанностей
      по настоящему Договору.
    </p>
    <p>
      8.10.2. Применение к Исполнителю контролирующими органами мер воздействия
      и / или финансовых санкций, основанием для которых стало ненадлежащее
      исполнение Пользователем своих обязанностей по настоящему Договору.
    </p>
    <p>
      8.11. Стороны согласовали, что за нарушение условий настоящего Договора
      любыми лицами, которым Пользователь предоставил доступ к Контенту, несет
      ответственность Пользователь.
    </p>
    <p>
      <b>9. Конфиденциальность и защита информации</b>
    </p>
    <p>
      9.1. Для пользования Услугой, Пользователь не предоставляет свои
      персональные данные, такие как: фамилия, имя, отчество, номер телефона,
      паспортные и/или другие данные, идентифицирующие личность. Необходимы лишь
      технические данные об оборудовании, (IP адрес, порт(-ы), логин и прочее от
      Конечного Оборудования Пользователя), которые вводятся Пользователем
      самостоятельно в Личном кабинете Пользователя.
    </p>
    <p>
      При регистрации, Пользователь вводит &laquo;имя&raquo; (псевдоним) - это
      может быть вымышленное имя/ прозвище/псевдоним, и используется
      Исполнителем исключительно с целью обращения к Пользователю при переписке
      по электронной почте и в Личном Кабинете.
    </p>
    <p>
      Таким образом, Исполнитель вообще не имеет персональных данных
      Пользователей.
    </p>
    <p>
      9.2. Исполнитель считает, что вся техническая информация по использованию
      Услуги Пользователем ровно, как и содержание всего Контента Пользователя
      является конфиденциальной. Исполнитель не ведет просмотр Контента
      Пользователя и не ведет наблюдения за видеотрансляциями Пользователя.
      Исполнитель не передает любые сведения Пользователя третьим лицам, кроме
      случаев, предусмотренных законодательством.
    </p>
    <p>
      9.3. Пользователь соглашается с тем, что он несет ответственность за
      сохранение конфиденциальности данных (логин/пароль), предоставляющих
      доступ в Личный Кабинет и других паролей, которые предоставляют доступ к
      оборудованию Пользователя, и связанные с Личным Кабинетом Пользователя.
      Согласно выше изложенному, Пользователь соглашается, что он несет
      исключительную ответственность перед Исполнителем за все действия, которые
      совершены при использовании своего Личного Кабинета.
    </p>
    <p>
      9.4. Все данные, предоставленные Пользователем на Сайте Исполнителя и/или
      в Личном Кабинете, шифруются с помощью SSL (http протокол). Контент
      пользователя шифруется на серверах Исполнителя, алгоритм шифрования
      является коммерческой тайной.
    </p>
    <p>
      9.5. Согласно этому разделу Пользователь несет исключительную
      ответственность за предоставление доступа другим Пользователям к своему
      Контента.
    </p>
    <p>
      <b>10. КОНТЕНТ УСЛУГИ</b>
    </p>
    <p>
      10.1. Пользователь признает, что ответственность за любой Контент (такой
      как видео файлы, звуковые файлы, онлайн видео и аудио трансляция, фото
      изображения с видеокамер и другого конечного Оборудование пользователя), к
      которой Пользователь может иметь доступ как к части Услуги, или из-за
      использования Услуги, несет Пользователь - владелец Контента, который
      предоставил такую информацию.
    </p>
    <p>
      10.2. Пользователь признает, что он несет исключительную ответственность
      за любой Контент, который создается, передается, хранится или
      воспроизводится на экране в процессе пользования Услугой, а также за все
      последствия таких действий.
    </p>
    <p>
      10.3. Исполнитель не несет ответственность за использование Пользователем
      Контента, созданного в процессе пользования Услугой, в частности: передачи
      сохраненного Контента третьим лицам и предоставления доступа к своему
      Личному Кабинету третьим лицам, а также за предоставление доступа другим
      Пользователям к просмотру.
    </p>
    <p>
      10.4. Пользователь признает, что он, как владелец своего Контента, несет
      полную ответственность за предоставление доступа к Контенту своего Личного
      Кабинета другим Пользователям, в ходе пользования Услугой, как часть
      Услуги.
    </p>
    <p>
      10.5. Пользователь несет ответственность за нарушение неприкосновенности
      частной жизни третьих лиц при пользовании Услугой, и гарантирует, что к
      моменту записи и хранения Контента получил согласие третьих лиц, если
      таковое требуется согласно законодательству, на запись и хранение их
      изображения, или изображения объектов, правами на которые Пользователь не
      обладает.
    </p>
    <p>
      <b>
        11. СРОК ДЕЙСТВИЯ ДОГОВОРА, ПРЕКРАЩЕНИЕ И ИЗМЕНЕНИЕ УСЛОВИЙ ДОГОВОРА
      </b>
    </p>
    <p>
      11.1. Настоящий Договор вступает в силу с даты акцепта, определенной
      соответствующими условиями настоящего Договора и действует бессрочно.
    </p>
    <p>
      11.2. Настоящий Договор прекращает действие досрочно, в предусмотренных
      им, или действующим законодательством Украины случаях.
    </p>
    <p>
      11.3. Исполнитель оставляет за собой право внесения в любое время
      изменений в настоящий Договор, которые вступают в силу не ранее чем через
      10 (десять) календарных дней со дня их опубликования на Сайте Исполнителя.
      При внесении таких изменений, Исполнитель может разместить новую редакцию
      Договора путем изложения его на Сайте Исполнителя.
    </p>
    <p>
      11.4. В случае несогласия Пользователя с изменениями условий Договора,
      Пользователь обязан прекратить использование Услуги. Продолжение
      использования Услуги означает предоставление согласия Пользователя на
      использование Услуги на условиях измененного договора.
    </p>
    <p>
      11.5. Пользователь может в одностороннем порядке прекратить действие
      Договора с Исполнителем, сообщив Исполнителю об этом в любое время.
      Расторжение договора по инициативе Пользователя осуществляется с учетом
      условий настоящего Договора.
    </p>
    <p>
      <b>12. ДРУГИЕ УСЛОВИЯ ДОГОВОРА</b>
    </p>
    <p>
      12.1. Настоящий Договор и отношения между Пользователем и Исполнителем
      регулируются действующим законодательством Украины. Пользователь и
      Исполнитель соглашаются использовать при возникновении любых споров,
      возникающих в связи с этими Условиями, юрисдикцию судов Украины.
    </p>
    <p>
      12.2. Стороны обязуются обеспечить актуальность и конфиденциальность всей
      информации по настоящему Договору, включая атрибуты авторизованного
      доступа, банковские реквизиты, адреса Сторон и другие данные, кроме
      случаев, предусмотренных действующим законодательством Украины.
    </p>
    <p>
      12.3. Признание недействительным отдельных положений настоящего Договора
      не влечет за собой недействительность всего Договора. В таком случае
      Договор продолжает действовать без учета недействительных положений, а
      Стороны вносят необходимые изменения в условия настоящего Договора.
    </p>
    <p>
      12.4. Акцептом (принятием) настоящего Договора Пользователь, в
      соответствии с Законом Украины &laquo;О защите персональных данных&raquo;,
      проинформирован о своих правах путем получения соответствующей информации
      с сайта Исполнителя, и дает согласие на протяжении всего срока
      существования Исполнителя:
    </p>
    <ul>
      <li>
        <p>
          на обработку его персональных данных Исполнителем, в объеме и целях,
          необходимых для обеспечения реализации налоговых отношений и отношений
          в сфере бухгалтерского учета, исполнения Сторонами условий настоящего
          Договора, или иных условий, имеющих прямое или косвенное отношение по
          его заключению, действия, исполнения и прекращения отношений в сфере
          сбора персональных данных в коммерческих целях (для информирования об
          услугах, рекламные акции, обращение с офертой и т.д.), согласно
          установленной Исполнителем процедуры их обработки, без установки
          каких-либо ограничений прав Исполнителя на их обработку;
        </p>
      </li>
      <li>
        <p>
          на распространение Исполнителем (без обязанности уведомления
          Пользователя) любыми каналами связи персональных данных Исполнителя
          третьим лицам, которые прямо или косвенно имеют отношение по
          заключению, действию, исполнению или прекращению настоящего Договора;
          лицам, право доступа к персональным данным (их источникам) которым
          предоставлено в соответствии с законодательством Украины;
          распорядителям персональных данных.
        </p>
      </li>
      <li>
        <p>
          Исполнитель гарантирует Пользователю соблюдение норм Закона Украины
          &laquo;О защите персональных данных&raquo;, во время обработки им
          персональных данных Пользователя.
        </p>
      </li>
    </ul>
    <p>
      12.5. Заказчик соглашается, что Исполнитель вправе направлять сообщения,
      включая сообщение о внесении изменений в условия использования Услуги, с
      помощью e-mail, push-сообщений или путем размещения информации на сайте
      Исполнителя.
    </p>
    <p>
      12.6. По всем остальным вопросам, не урегулированным настоящим Договором,
      Стороны руководствуются действующим законодательством Украины.
    </p>
    <p>
      <b>13. ФОРС МАЖОР</b>
    </p>
    <p>
      13.1. Стороны освобождаются от ответственности за полное или частичное
      невыполнение своих обязательств по настоящему Договору, если такое
      невыполнение явилось следствием обстоятельств форс-мажора, которые
      возникли после заключения настоящего Договора и которые стороны не могли
      ни предвидеть, ни предотвратить разумными мерами.
    </p>
    <p>
      13.2. В случае возникновения форс-мажорных обстоятельств действие
      настоящего Договора приостанавливается до окончания действия форс-мажорных
      обстоятельств.
    </p>
    <p>
      13.3. К обстоятельствам форс-мажора относятся: наводнение, пожар,
      землетрясение, другие стихийные бедствия, а также война, военные действия,
      незаконные действия органов государственной власти и управления и любые
      другие обстоятельства вне разумного контроля Сторон, имеющих
      непосредственное существенное негативное влияние на выполнение
      обязательств по настоящему Договору, однако, при условии, что такие
      обстоятельства не возникли вследствие несоблюдения Стороной действующего
      законодательства, невыполнение стороной каких-либо обязательств,
      касающихся любых заключенных между Сторонами соглашений.
    </p>
    <p>
      13.4. Сторона, которая ссылается на наступление форс-мажорных
      обстоятельств, должна подтвердить его документально соответствующими
      справками компетентных органов.
    </p>
    <p>
      <b>14. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</b>
    </p>
    <p>
      Наименование Исполнителя: ФОП Лежнев Владимир Кузьмич, ЕДРПОУ 2120421650.
      <br />
      Юр. адрес: Киев, ул. Иорданская, 9-Е, кв. 194
      <br />
      E-mail: support@ipteamcloud.com
      <br />
      Телефон: +380 (93) 361-88-99
      <br />
    </p>
    <p style={{ textAlign: 'center' }}>
      <b>
        АКЦЕПТ ПУБЛИЧНОГО ДОГОВОРА ОБ УСЛУГАХ ТЕРРИТОРИАЛЬНО-РАСПРЕДЕЛЕННОГО
        ВИДЕОНАБЛЮДЕНИЯ <br />
        (на условиях публичной оферты)
      </b>
    </p>
    <p>
      Пользователь, руководствуясь нормами статей 638 Гражданского кодекса
      Украины, принимаю публичное предложение (оферту) сервиса ipteamcloud
      (далее &mdash; Исполнитель), опубликованную на сайте Исполнителя{' '}
      <a
        href="http://www.ipteamcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.ipteamcloud.com
      </a>
      , по заключению договора об использовании Услуги
      территориально-распределенного видеонаблюдения через Интернет (далее
      &mdash; оферта), следующим образом:
    </p>
    <p>при заключении со мной договора Услуги видеонаблюдения через Интернет</p>
    <p>
      1. Ставя отметку &laquo;Птичка&raquo; в форме Регистрации напротив
      утверждения &laquo;Я согласен и принимаю условия пользования&raquo;
      подтверждаю, что:
    </p>
    <ul>
      <li>
        <p>
          принимаю условия Договора о предоставлении услуги
          территориально-распределенного видеонаблюдения через сеть Интернет;
        </p>
      </li>
      <li>
        <p>
          принятие этого Акцепта является моим свободным волеизъявлением,
          соответствует моей воле и интересам, и никоим образом не нарушает мои
          права;
        </p>
      </li>
      <li>
        <p>как Заказчик вполне осознаю природу сделки, которая совершается.</p>
      </li>
    </ul>
    <p>2. Оферта этот Акцепт вместе составляют единый документ.</p>
    <p>
      3. Оферта считается принятой с момента обозначения &laquo;Птички&raquo; в
      форме Регистрации напротив утверждения &laquo;Я согласен и принимаю
      условия пользования&raquo; и Регистрации на Сайте Исполнителя.
    </p>
  </div>
);

export default AgendaRu;
